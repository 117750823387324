import React from "react";
import "./Home.css";
function Home() {
  return (
    <div className="home">
      <div className="hero-section">
        <div className="left-hero">
          <div className="hero-maintext">
            <div
              className="hero-maintext first"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {".nu" + " "}
            </div>
            <div className="hero-maintext second">
              <span>
                80% av frågor besvarar AI
                <br />
                vi bygger en chatbot
                <br />
                specialbyggd för ditt syfte
                <br />
                allt är gratis tills du är nöjd
                <br />
              </span>
            </div>
          </div>
          <div className="hero-smalltext">
            Boka demo och inom en vecka kan du ha en specialgjord chatbot på din
            hemsida!
          </div>
          <div className="button herobutton">Boka Demo</div>
        </div>
        <div className="right-hero">
          <div className="video-container">
            <div class="video">
              <video autoplay="" loop muted playsinline="">
                <source src="/media/extensiontutorial.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
