import React from "react";
import "./Header.css";
function Header() {
  return (
    <div className="main-header">
      <div className="header-container">
        <div className="header-logo">trafikchat.se</div>
        <div className="header-menu">
          <div className="menu-button">Produkt</div>
          <div className="menu-button">Lösningar</div>
          <div className="menu-button">Priser</div>
          <div className="menu-button">Boka samtal</div>
          <div className="menu-button">About Us</div>
        </div>
        <div className="header-buttons">
          <div className="button grey-country-button">SV</div>
          <div className="button book-demo-button">Booka Demo</div>
        </div>
      </div>
    </div>
  );
}

export default Header;
